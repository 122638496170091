import * as React from 'react';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from '@invertase/ui';
import { graphql, useStaticQuery } from 'gatsby';
import cx from 'classnames';
import { ToastProvider } from 'react-toast-notifications';

import '../tailwind.css';
import useDarkMode from '../hooks/useDarkMode';

import { LocationContext } from '../context';

interface Props {
  location: Location;
  title?: string;
  description?: string;
  children: React.ReactNode | React.ReactNode[] | string;
  className?: string;
  noindex?: boolean;
  header?: React.ReactElement;
}

function Page({
  location,
  title,
  description,
  children,
  className,
  noindex = false,
  header,
}: Props) {
  // Enables Dark mode class
  useDarkMode();

  const { site } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);

  const meta = {
    title: title ? `${title} | ${site.siteMetadata.title}` : site.siteMetadata.title,
    description: description || site.siteMetadata.description,
    favicon: '/logo-icon.png',
    logo: '',
    image: '',
  };

  return (
    <>
      <Helmet>
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
        <link rel="icon" type="image/png" href={meta.favicon} />
        <meta name="image" content={meta.image} />
        {noindex && <meta name="robots" content="noindex" />}

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={meta.title} />
        <meta name="twitter:description" content={meta.description} />

        {/* OG Card */}
        <meta name="og:title" content={meta.title} />
        <meta name="og:description" content={meta.description} />
        <meta name="og:image" content={meta.image} />
        {/* <meta name="og:url" content={route} /> */}
        <meta name="og:site_name" content="Notifee" />
        <meta name="og:type" content="website" />
      </Helmet>
      <ToastProvider autoDismiss autoDismissTimeout={5000} placement="bottom-center">
        <LocationContext.Provider value={location}>
          <ThemeProvider value="notifee">
            <div className="font-sans">
              {header}
              <div id="notifee" className={cx('h-full', className)}>
                <main
                  className={cx({
                    'pt-16': !!header,
                  })}
                >
                  {children}
                </main>
              </div>
            </div>
          </ThemeProvider>
        </LocationContext.Provider>
      </ToastProvider>
    </>
  );
}

export default Page;
