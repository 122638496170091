import * as React from 'react';
import cx from 'classnames';

interface Props {
  children: React.ReactNode | React.ReactNode[];
  size?: 'base' | 'sm' | 'xs';
}

function Container({ children, size = 'base' }: Props) {
  return (
    <div
      className={cx('mx-auto px-2', {
        'max-w-7xl': size === 'base',
        'max-w-5xl': size === 'sm',
        'max-w-3xl': size === 'xs',
      })}
    >
      {children}
    </div>
  );
}

export default Container;
