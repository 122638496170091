import hook from 'use-dark-mode';

interface UseDarkMode {
  toggleDarkMode: () => void;
}

export default function useDarkMode(): UseDarkMode {
  const { toggle } = hook(false, {
    classNameLight: 'mode-light',
    classNameDark: 'mode-dark',
  });

  return {
    toggleDarkMode: toggle,
  };
}
